<template>
  <base-section
    id="features"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          icon: 'mdi-target-account',
          title: 'Přímá komunikace',
          text: 'U nás komunikujete přímo a bez prostředníků. Žádné zdržovaní.',
        },
        {
          icon: 'mdi-update',
          title: 'Podpora 24/7',
          text: 'Víme, že auta potřebují péči i mimo obvyklou pracovní dobu.',
        },
        {
          icon: 'mdi-account-check',
          title: 'Spolehlivé reference',
          text: 'Rádi Vám smluvíme telefonickou konzultaci s našimi stávajícími klienty.',
        },
        {
          icon: 'mdi-star-outline',
          title: 'Dlouholetá praxe',
          text: 'Více než 30 let zkušeností v automobilové sféře.',
        },
      ],
    }),
  }
</script>
